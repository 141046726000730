.container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    max-width: 1280px;
    margin: 16px auto;

    .heading {
        width: 100%;
        border: 1px solid black;
        border-radius: 4px;
        background-color: white;
        display: flex;
        padding: 16px 32px;
        align-items: center;
        height: 128px;

        & h2 {
            width: 15%;
            text-align: left;
            font-size: 3.2rem;
        }

        & .waiting {
            width: 70%;
            font-size: 5.2rem;
            letter-spacing: 8px;
        }

        & h3 {
            width: 15%;
            font-size: 4.6rem;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            align-items: baseline;

            p {
                padding-right: 8px;
            }
        }
    }

    .gameContent {
        display: flex;
        // flex-wrap: nowrap;
        width: 100%;
        max-width: 1280px;
    }
}

@media screen and (max-width: 1268px) {
    // Global CSS changes to make view 100% height
    html {
        height: 100vh;
    }

    body {
        overflow: hidden;
        height: 100vh;

        & > div {
            height: 100vh;

            & > div {
                height: 100vh;
            }
        }
    }

    .App {
        height: 100vh;
    }

    // Specific to the game UI

    .container {
        height: 100vh;
        margin: 0;

        // Heading
        & > :nth-child(1) {
            height: 5vh;
            padding: 0;

            & > :nth-child(2) {
                font-size: 2rem;
                letter-spacing: 1px;
            }

            & > :nth-child(3) {
                font-size: 2rem;
                letter-spacing: 1px;
                align-items: center;
            }
        }

        // Game Content
        & > :nth-child(2) {
            height: 95vh;
        }
    }

    .heading {
        height: 5vh;
        margin: 4px auto;
    }

    .gameContent {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        margin: 0;

        // Canvas container
        & > :nth-child(2) {
            width: 100%;
            height: 66vh;
            order: 0;
            margin: 0;

            // Canvas parent
            & > div:nth-of-type(1) {
                width: 100%;
                height: 55vh;
            }

            // Canvas colors
            & > div:nth-of-type(2) {
                width: 100%;
                height: 5vh;
            }

            // Canvas size
            & > div:nth-of-type(3) {
                width: 100%;
                height: 2vh;
            }

            // Canvas tools
            & > div:nth-of-type(4) {
                width: 100%;
                height: 1vh;
            }

            // Canvas
            & > div > canvas {
                width: 100%;
                height: 55vh;
            }
        }

        // Players
        & > :nth-child(1) {
            width: 50%;
            order: 1;
            margin: 0;
            overflow-x: hidden;
            overflow-y: auto;
            height: 28vh;

            // Player
            & div {
                width: 98%;
                margin: 4px auto;
            }
        }

        // Chat
        & > :nth-child(3) {
            width: 50%;
            order: 2;
            margin: 4px auto;
            height: 28vh;

            & > form > input {
                width: 75%;
            }
        }
    }
}
