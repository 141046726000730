.players {
    margin: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    // border: 1px solid black;
    // background-color: white;
    border-radius: 4px;
    width: 256px;
    height: 600px;
    // overflow-y: auto;

    & div {
        padding: 5.5px;
        border-bottom: 1px solid black;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 70px;
        transition: background-color .5s;
        background-color: white;
        border-radius: 4px;
        margin-bottom: 6px;

        &:last-of-type {
            // border-bottom: none;
        }
        
        & strong {
            font-size: 2rem;
        }

        & p {
            font-size: 1.8rem;
            margin: 4px;
        }

        &.answered {
            background-color: rgb(148, 255, 148);
        }

        // &:nth-of-type(even) {
        //     background-color: rgba(255, 255, 255, .85);

        //     &.answered {
        //         background-color: rgb(30, 197, 30);
        //     }
        // }
    }
}