.canvasContainer {
    margin-top: 8px;
}

.canvasParent {
    position: relative;
    width: 728px;
    height: 600px;
}

.canvas {
    background-color: white;
    border-radius: 4px;
    position: absolute;
    width: 728px;
    height: 600px;
    left: 0;
    right: 0;
}

.sliderContainer {
    width: 100%;
    justify-content: center;
    margin: 4px;
}

.slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 50%;
    height: 8px;
    background: #9d9d9d;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 4px;
    margin: 0 auto;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            border: solid #37d4ff 2px;
        }
    }

    &::-moz-range-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            border: solid #37d4ff 2px;
        }
    }
}

button {
    border-radius: 4px;
    transition: border .2s;

    &.selected {
        border: solid #0eb7ff 2px;
    }

    &:hover {
        border: solid #0eb7ff 2px;
    }
}