.chatBox {
    flex-grow: 2;
    margin: 8px 0 0 8px;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    background-color: white;
    border-radius: 4px;
    height: 600px;
    width: 256px;

    & .chatMessages {
        flex-grow: 1;
        font-size: 1.8rem;
        overflow-y: auto;
        bottom: 0;
        max-height: 100%;

        & div {
            width: 100%;
            text-align: left;
            padding: 2px 0px 2px 2px;
            word-wrap: break-word;
            & span {
                font-weight: 600;
                &:last-of-type {
                    font-weight: 400;
                }
            }

            &:nth-of-type(even) {
                background-color: rgba(0, 0, 0, .05);
            }
        }
    }

    & .chatInput {
        display: flex;
        width: 100%;
        justify-self: flex-end;

        & input {
            flex-grow: 1;
            padding: 8px;
            margin: 4px 4px 0px 0px;
        }

        & button {
            padding: 8px;
            margin: 4px 0px 0px 4px;
        }
    }
}