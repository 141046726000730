.title {
    font-size: 4rem;
    font-family: cursive;
    color: white;
    -webkit-text-stroke: black 1.5px;
    margin-top: 6rem;
}

.container {
    width: 35%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    padding: 2rem 0rem;
    margin: 4rem auto 32px auto;

    input {
        width: 95%;
        font-size: 2rem;
    }

    button {
        width: 95%;
        border-radius: 4px;

        &.play {
            font-size: 2rem;
            font-weight: 500;
            padding: 1.3rem 0rem;
            background-color: #2754b3;
            color: white;

            &:hover {
                background-color: #366cdf;
            }
        }

        &.private {
            font-size: 1.8rem;
            font-weight: 400;
            padding: .5rem 0rem;
            background-color: #67ca67;
            color: white;      
            
            &:hover {
                background-color: #7af17a;
            }
        }
    }
}

.discord {
    color: white;
    font-size: 2.4rem;

    &:visited {
        color: white;
    }
}