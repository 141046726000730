.container {
    width: 728px;
    height: 600px;
    border-radius: 2px;
    background-color: #00000095;
    left: 0;
    right: 0;
    position: absolute;
}

.currentDrawer {
    width: 100%;
    text-align: center;
    color: #ffffff;
    padding: 6px 0px 0px 0px;

    span {
        color: #fff700;
        font-size: 18px;
    }
}

.word {
    font-size: 48px;
    color: #ffffff;
    padding: 0px 0px 6px 0px;
    border-bottom: solid black 3px;
    -webkit-text-stroke: 2px black;
    position: relative;
    margin-top: -8px;
    margin-bottom: 8px;
}

.players {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
}

.player {
    display: flex;
    width: 75%;
    font-size: 2.8rem;
    height: 48px;
    background-color: rgb(225, 225, 225);
    border: solid black 1px;
    border-radius: 500px;
    margin: 4px auto;

    .name {
        width: 67%;
        text-align: center;
    }

    .score {
        width: 33%;
        text-align: left;
    }
}

.counter {
    text-align: center;
    margin-top: 32px;
    color: #ffffff;
    font-size: 20px;
}

.stroke {
    -webkit-text-stroke: 0.8px black;
}

@media screen and (max-width: 1268px) {
    .container {
        height: 55vh;
        width: 100%;
    }

    .player {
        width: 95%;
    }
}
